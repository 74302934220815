import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import KitchenRecipeBlocks from '../components/sections/kitchen-recipe-blocks'
import Seo from '../components/seo'
import Layout from '../components/layout'
import HowToCook from '../components/sections/how-to-cook'
import {
  steakGrill,
  steakSkillet,
  cutletGrill,
  cutletSkillet,
  crispyOven,
  crispyAirFryer,
  carneGrill,
  carneSkillet,
  breakfastPatty,
} from '../content/kitchen'
import TipsAndTricks from '../components/common/tips-and-tricks'
import HowDoYouMeati from '../components/sections/how-do-you-meati'

import Bubble from '../components/svg-icons/bubble'
import KitchenHero from '../components/common/kitchen-hero'

import CookingTipsPopup from '../components/common/popup-cooking-tips'

const KitchenPage = props => {
  const sections = [
    {
      handle: 'classic-steak',
      title: 'classic steak',
      optionAContent: steakGrill,
      optionATitle: 'Grill',
      optionBContent: steakSkillet,
      optionBTitle: 'Skillet',
      video: '721510452?h=e436be9224',
      type: 'steak',
    },
    {
      handle: 'classic-cutlet',
      title: 'classic cutlet',
      optionAContent: cutletGrill,
      optionATitle: 'Grill',
      optionBContent: cutletSkillet,
      optionBTitle: 'Skillet',
      video: '721510413?h=73e55df7c4',
      type: 'chicken',
    },
    {
      handle: 'crispy-cutlet',
      title: 'crispy cutlet',
      optionAContent: crispyAirFryer,
      optionATitle: 'Air Fryer',
      optionBContent: crispyOven,
      optionBTitle: 'Oven',
      video: '721510432?h=2b15991fbe',
      type: 'chicken',
    },
    {
      handle: 'carne-asada',
      title: 'carne asada',
      optionAContent: carneGrill,
      optionATitle: 'Grill',
      optionBContent: carneSkillet,
      optionBTitle: 'Skillet',
      video: '769998875?h=4aee41d145',
      type: 'steak',
    },
    {
      handle: 'breakfast-patty',
      title: 'breakfast patty',
      optionBContent: breakfastPatty,
      optionBTitle: 'Skillet',
      foodImage: (
        <StaticImage
          src="../images/meati-breakfast-patty.png"
          style={{ borderRadius: '1rem' }}
          quality={95}
          placeholder=""
          alt="Mo Preferred Sticker"
          layout="fullWidth"
        />
      ),
      type: 'patty',
      recipeBlocksDisabled: true,
    },
  ]

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    // if url doesn't have a hash, scroll to top
    const { hash } = props.location
    !hash && gsap.to(window, { duration: 0.35, scrollTo: 0 })

    function getNavHeight() {
      return document.querySelector('#navBar').offsetHeight - 1
    }

    // make an array of nav links
    const links = gsap.utils.toArray('.kitchen-bubble')
    const sections = gsap.utils.toArray('.kitchen-section')

    /* Click nav links to scroll to section */
    links.forEach((link, i) => {
      link.addEventListener('click', () => {
        gsap.to(window, { duration: 0.7, scrollTo: { y: sections[i], offsetY: 150 } })
      })
    })

    // scrolltrigger for sticky nav
    ScrollTrigger.create({
      trigger: '#kitchenNav',
      start: `top top+=${getNavHeight() + 40}px`,
      end: 'top bottom',
      endTrigger: '#footer',
      pin: true,
      pinSpacing: false,
    })

    // make nav links active while section is visible
    sections.forEach((section, i) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top 60%',
          end: 'bottom 60%',
          toggleClass: { targets: links[i], className: 'active' },
          scrub: false,
        },
      })
    })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach(trigger => {
          trigger.kill()
        })
      }
    }
  }, [])

  return (
    <Layout>
      <Seo title="Get Cookin'" />
      <KitchenHero />
      <nav id="kitchenNav" className="shadow-lg pt-12 pb-14 bg-white secondary-nav">
        <Container>
          <ul className="list-unstyled mb-0 d-flex justify-content-center flex-wrap">
            <li className="text-uppercase text-muted d-flex align-items-center justify-content-center me-sm-1">
              <small>How to cook:</small>
            </li>
            {sections.map((section, index) => (
              <li key={`bubble-${index}`} className="kitchen-bubble d-flex justify-content-center" role="button">
                <Bubble type={section.type} text={section.title} />
              </li>
            ))}
          </ul>
        </Container>
      </nav>
      {sections.map((section, index) => (
        <section
          key={`bubble-${index}`}
          className="pt-2 pt-md-3 kitchen-section"
          id={section.handle}
          data-type={section.type}
        >
          <HowToCook
            title={section.title}
            optionAContent={section.optionAContent}
            optionATitle={section.optionATitle}
            optionBContent={section.optionBContent}
            optionBTitle={section.optionBTitle}
            video={section.video}
            foodImage={section.foodImage}
            handle={section.handle}
          />
          {!section.recipeBlocksDisabled && (
            <KitchenRecipeBlocks handle={section.handle} title={`${section.title} recipes`} />
          )}
        </section>
      ))}
      <TipsAndTricks />
      <HowDoYouMeati />
      <CookingTipsPopup />
    </Layout>
  )
}

export default KitchenPage

import * as React from 'react'

import { Link } from 'gatsby'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api'
import { useEffect, useState, createRef } from 'react'
import { gsap } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Modal from '@mui/material/Modal'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { faMobile } from '@fortawesome/pro-regular-svg-icons'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import FormCheck from 'react-bootstrap/FormCheck'
import { Pagination } from 'react-bootstrap'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { all } from 'axios'
import RequestForm from '../components/common/request-form'
import MapDistanceDropdown from '../components/common/map-distance-dropdown'
import GTMPush from '../components/utilities/gtm-push'
import { ErrorMapOverlay } from '../components/common/error-map-overlay'
import ShoppingImage from '../images/icons/shopping.svg'
import ShoppingCheckedImage from '../images/icons/shopping-checked.svg'
import DiningImage from '../images/icons/dining.svg'
import DiningCheckedImage from '../images/icons/dining-checked.svg'
import ModalContext from '../components/context/modal-context'
import Seo from '../components/seo'
import Layout from '../components/layout'
import FieldGoogleAutocomplete from '../components/common/field-google-autocomplete'

import useFetch from '../hooks/use-fetch'
import useOptions from '../hooks/use-options'

const FindPage = ({ location }) => {
  const containerStyle = {
    width: '100%',
    height: '600px',
  }

  const [defaultCuts, setDefaultCuts] = useState([])
  const defaultRF = ['retail', 'foodservice']
  const [libraries] = useState(['places'])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API,
    libraries,
  })

  const [map, setMap] = React.useState(null)
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [infoWindowData, setInfoWindowData] = useState(null)
  const [infoWindowIndex, setInfoWindowIndex] = useState(null)
  const [locationList, setLocationList] = useState([])
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [latLong, setLatLong] = useState(null)
  const [searchLocation, setSearchLocation] = useState(null)
  // map specific settings
  const [mapDistance, setMapDistance] = useState(20)
  const [mapLatLong, setMapLatLong] = useState({})
  const [channels, setChannels] = useState(defaultRF)
  const [retailChannelDisabled, setRetailChannelDisabled] = useState(false)
  const [retailChannelActive, setRetailChannelActive] = useState(true)
  const [diningChannelDisabled, setDiningChannelDisabled] = useState(false)
  const [diningChannelActive, setDiningChannelActive] = useState(true)
  const [allCuts, setAllCuts] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [paginationItems, setPaginationItems] = useState([])
  const [paginationEnabled, setPaginationEnabled] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [searchValue, setSearchValue] = useState('Enter your address')
  const [firstLocationRequest, setFirstLocationRequest] = useState(true)
  // aut complete
  const [autocomplete, setAutocomplete] = useState(null)
  const [placeSelected, setPlaceSelected] = useState('')
  const [acPlaceHolder, setAcPlaceHolder] = useState('Enter your address')

  const [isLoading, setIsLoading] = useState(false)
  const [locationOnlyOnce, setLocationOnlyOnce] = useState(false)

  const { data, error, loading, fetchData } = useFetch()
  const { optionsData, optionsError, optionsLoading } = useOptions()

  const allRef = createRef()
  const fieldRef = createRef()
  const refs = React.useRef({})

  gsap.registerPlugin(ScrollToPlugin)

  const [center, setCenter] = useState({
    lat: 39.755762354348626,
    lng: -104.890136941618,
  })

  const checkAllCheckboxes = () => {
    Object.keys(refs.current).forEach(item => {
      if (refs.current.hasOwnProperty(item)) {
        refs.current[item].current.checked = true
      }
    })
    allRef.current.checked = true
  }

  const addToCuts = e => {
    if (e.target.checked) {
      setAllCuts([...allCuts, e.target.value])
    } else {
      setAllCuts(allCuts.filter(item => item !== e.target.value))
    }
    allRef.current.checked = false

    if (allCuts.length === 1) {
      Object.keys(refs.current).forEach(item => {
        if (refs.current.hasOwnProperty(item)) {
          refs.current[item].current.disabled = false
        }
      })
    }
  }

  useEffect(() => {
    if (allCuts.length === 1) {
      Object.keys(refs.current).forEach(item => {
        if (refs.current.hasOwnProperty(item)) {
          if (refs.current[item].current.checked === true) {
            refs.current[item].current.disabled = true
          }
        }
      })
    }
  }, [allCuts])

  const cleanProductSet = products => {
    const renderedProductNames = new Set()
    const finalProducts = products.map((product, index) => {
      if (renderedProductNames.has(product.product_name)) {
        return null // Skip rendering this product
      }
      renderedProductNames.add(product.product_name)

      return (
        <p className="text-nowrap m-0" key={index}>
          {product.product_name}
        </p>
      )
    })

    return finalProducts
  }

  const resetMenu = () => {
    const menuItems = document.getElementsByClassName('list-group-item')
    if (menuItems.length) {
      for (const elem in menuItems) {
        if (menuItems[elem].classList !== undefined) {
          menuItems[elem].classList.remove('active')
        }
      }
    }
  }

  useEffect(() => {
    if (
      searchValue !== null &&
      searchValue !== undefined &&
      searchValue !== 'Enter your address' &&
      firstLocationRequest !== true
    ) {
      geocodeByAddress(searchValue.label)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          setMapLatLong({
            lat,
            lng,
          })
        )
    }
  }, [searchValue])

  useEffect(() => {
    if (optionsData) {
      setDefaultCuts(optionsData)
      setAllCuts(optionsData)
    }
  }, [optionsData])

  // end auto complete

  useEffect(() => {
    if (infoWindowIndex !== null) {
      resetMenu()
      const menuItems = document.getElementsByClassName('list-group-item')
      menuItems[infoWindowIndex].classList.add('active')
      gsap.to(window, { duration: 0.5, delay: 0.5, scrollTo: { y: 259 }, ease: 'Power1.easeInOut' })
    }
  }, [infoWindowIndex])

  useEffect(() => {
    const request = new XMLHttpRequest()

    request.open('GET', 'https://api.ipdata.co/?api-key=464afcbaaa9280fb29a0ef2d8f322db35113c330e10f77ae5e23f658')

    request.setRequestHeader('Accept', 'application/json')

    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        setLatLong({ lat: JSON.parse(this.responseText).latitude, long: JSON.parse(this.responseText).longitude })
        setMapLatLong({ lat: JSON.parse(this.responseText).latitude, lng: JSON.parse(this.responseText).longitude })
        setCenter({ lat: JSON.parse(this.responseText).latitude, lng: JSON.parse(this.responseText).longitude })
        setAcPlaceHolder(
          `${JSON.parse(this.responseText).city}, ${JSON.parse(this.responseText).region_code}${
            JSON.parse(this.responseText)?.postal !== null ? ` ${JSON.parse(this.responseText)?.postal}` : ''
          }`
        )
        setSearchLocation(
          `${JSON.parse(this.responseText).city}, ${JSON.parse(this.responseText).region_code}${
            JSON.parse(this.responseText)?.postal !== null ? ` ${JSON.parse(this.responseText)?.postal}` : ''
          }`
        )
        setSearchValue(
          `${JSON.parse(this.responseText).city}, ${JSON.parse(this.responseText).region_code}${
            JSON.parse(this.responseText)?.postal !== null ? ` ${JSON.parse(this.responseText)?.postal}` : ''
          }`
        )
        setTimeout(() => {
          setFirstLocationRequest(false)
        }, 1000)
        GTMPush({
          event: 'map_initial_location',
          postal_code: JSON.parse(this.responseText).postal,
        })
      }
    }

    request.send()
  }, [])

  useEffect(() => {
    if (mapLatLong !== null && Object.keys(mapLatLong).length !== 0) {
      setIsLoading(true)
      GTMPush({
        event: 'search',
        text: placeSelected,
        search_type: 'store_locator',
        postal_code: '',
      })
      setCenter({ lat: mapLatLong.lat, lng: mapLatLong.lng })
      fetchData(mapLatLong, mapDistance, allCuts, channels)
    }
  }, [mapDistance, channels, allCuts, mapLatLong, activePage])

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const { brand } = queryString.parse(location.search)

      const targetString = brand // Replace this with any string you want to search for

      const sortByChainName = (a, b, target) => {
        const aChainName = a.location_details.chain_name
          ? a.location_details.chain_name.toLowerCase()
          : a.location_details.name
          ? a.location_details.name.toLowerCase()
          : ''
        const bChainName = b.location_details.chain_name
          ? b.location_details.chain_name.toLowerCase()
          : b.location_details.name
          ? b.location_details.name.toLowerCase()
          : ''
        const aIncludesTarget = aChainName.includes(target)
        const bIncludesTarget = bChainName.includes(target)

        if (aIncludesTarget && !bIncludesTarget) return -1
        if (!aIncludesTarget && bIncludesTarget) return 1
        return 0
      }
      let sortedLocations = data

      if (sortedLocations && targetString) {
        const lowerCaseTargetString = targetString.toLowerCase()
        sortedLocations = sortedLocations
          .map((item, index) => ({ ...item, originalIndex: index }))
          .sort((a, b) => {
            const primarySort = sortByChainName(a, b, lowerCaseTargetString)
            if (primarySort === 0) {
              return a.originalIndex - b.originalIndex
            }
            return primarySort
          })
          .map(item => {
            const { originalIndex, ...rest } = item
            return rest
          })
      }

      setLocationList(sortedLocations)
      setTotalPages(data.total_pages)
      setTotalItems(data.total_results)
      setIsLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (totalPages >= 2) {
      setPaginationEnabled(true)
      const tempPaginationItems = []
      // eslint-disable-next-line no-plusplus
      for (let number = 1; number <= totalPages; number++) {
        tempPaginationItems.push(
          <Pagination.Item
            key={`page${number}`}
            onClick={e => setActivePage(parseInt(e.target.text, 10))}
            active={number === activePage}
          >
            {number}
          </Pagination.Item>
        )
      }
      setPaginationItems(tempPaginationItems)
    }
  }, [totalPages, activePage])

  const updateChannels = value => {
    if (value === 'retail') {
      if (diningChannelDisabled) {
        setDiningChannelDisabled(false)
        setDiningChannelActive(true)
        setRetailChannelActive(true)
      } else {
        setRetailChannelDisabled(true)
        setDiningChannelActive(false)
      }
    } else if (value === 'foodservice') {
      if (retailChannelDisabled) {
        setRetailChannelDisabled(false)
        setRetailChannelActive(true)
        setDiningChannelActive(true)
      } else {
        setDiningChannelDisabled(true)
        setRetailChannelActive(false)
      }
    }
  }

  useEffect(() => {
    if (!diningChannelDisabled && !retailChannelDisabled) {
      setChannels(['retail', 'foodservice'])
    } else if (diningChannelDisabled) {
      setChannels(['foodservice'])
    } else if (retailChannelDisabled) {
      setChannels(['retail'])
    }
  }, [diningChannelDisabled, retailChannelDisabled])

  useEffect(() => {
    if (locationOnlyOnce === true) return
    if (acPlaceHolder !== 'Enter your address' && fieldRef.current !== null && autocomplete !== null) {
      fieldRef.current.value = acPlaceHolder
      setLocationOnlyOnce(true)
    }
  }, [acPlaceHolder, fieldRef, autocomplete, setLocationOnlyOnce])

  const SectionDivider = () => (
    <p className="mb-0">
      <hr className="border border-2 mt-12 mb-12" />
    </p>
  )

  const SectionTitle = props => (
    <p className="mb-0">
      <strong>{props.title}</strong>
    </p>
  )

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const formatDisplay = place => {
    if (place === 'Delivery: Powered by Instacart') {
      return 'Instacart Delivery'
    }
    if (place === 'Pickup: Powered by Instacart') {
      return 'Instacart Pickup'
    }
    return place
  }

  const mapMenuEvent = (e, node) => {
    e.preventDefault()
    resetMenu()
    e.target.classList.add('active')
    setInfoWindowData(node)
    setShowInfoWindow(true)
    setCenter({
      lat: parseFloat(node.latitude),
      lng: parseFloat(node.longitude),
    })

    GTMPush({
      event: 'select_content',
      postal_code: node.location_details.address_components.postal_code,
      store_id: node.id,
      content_type: 'store_selection',
      store_name: node.name,
      chain_name: node.location_details.chain_name,
      search_type: 'store_locator',
      order_type: 'delivery',
    })
  }

  const openNewPage = (e, location, type, data, storeId, orderType) => {
    GTMPush({
      event: 'select_content',
      postal_code: data.address_components?.postal_code,
      adLocation: location,
      store_id: storeId,
      content_type: 'store_link_out',
      order_type: orderType,
      store_name: data?.name,
      chain_name: data?.chain_name,
    })

    e.preventDefault()
    e.stopPropagation()
    window.open(location, '_blank')
  }

  return (
    <Layout className="where-is-meati">
      <Seo title="Where is meati?" />
      <div className="bg-light">
        <Container>
          <header id="whereIsHero" className="text-primary pt-2 pt-lg-3 mb-1 mb-lg-3">
            <h1 className="fw-bolder font-swish mb-1 mb-md-0">
              Find meati<sup style={{ fontSize: '37%', verticalAlign: 'super' }}>™</sup> near you
            </h1>
            <p className="fs-5 mb-0 lh-1">
              We're making our way to your neighborhood. Check out the map to find meati near you!
            </p>
          </header>
          <section>
            <div id="locator" className="align-items-end locator bg-white rounded-4">
              <div className="locator-toolbar p-1 d-lg-flex justify-content-between">
                <div className="locator-toolbar-left d-sm-flex justify-content-stretch mb-12 mb-lg-0">
                  <div className="locator-tool locator-tool__location position-relative flex-grow-1">
                    <p className="m-0 fs-5" style={{ marginBottom: '0.5rem' }}>
                      Location
                    </p>
                    <FieldGoogleAutocomplete
                      isLoaded={isLoaded}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                    />
                  </div>
                  <div
                    className="locator-tool locator-tool__distance ms-sm-1"
                    style={
                      {
                        /* zIndex: 1000 */
                      }
                    }
                  >
                    <p className="fs-5" style={{ marginBottom: '0.5rem' }}>
                      Distance
                    </p>
                    <MapDistanceDropdown
                      mapDistance={mapDistance}
                      setMapDistance={setMapDistance}
                      isLoading={isLoading}
                    />
                  </div>
                </div>

                <div className="locator-toolbar-right mt-sm-1 mt-lg-0 d-flex justify-content-between align-items-end">
                  <div className="locator-tool locator-tool__channels">
                    <p className="fs-5" style={{ marginBottom: '0.5rem' }}>
                      Shopping or Dining
                    </p>
                    <div className="d-flex">
                      <button
                        disabled={isLoading}
                        className={`btn btn btn-outline-${retailChannelActive ? 'primary' : 'secondary'}  
                                  rounded-pill me-14 d-flex align-items-center px-1 ${
                                    retailChannelDisabled ? 'disable-pointers' : 'enable-pointers'
                                  }`}
                        type="button"
                        onClick={
                          retailChannelDisabled
                            ? null
                            : () => {
                                updateChannels('retail')
                              }
                        }
                      >
                        <img
                          src={retailChannelActive ? ShoppingCheckedImage : ShoppingImage}
                          alt="Shopping Checked"
                          className="me-12"
                          height={15}
                        />
                        Shopping
                      </button>
                      <button
                        disabled={isLoading}
                        className={`btn btn btn-outline-${
                          diningChannelActive ? 'primary' : 'secondary'
                        } rounded-pill d-flex align-items-center px-1 ${
                          diningChannelDisabled ? 'disable-pointers' : 'enable-pointers'
                        }`}
                        type="button"
                        onClick={
                          diningChannelDisabled
                            ? null
                            : () => {
                                updateChannels('foodservice')
                              }
                        }
                      >
                        <img
                          src={diningChannelActive ? DiningCheckedImage : DiningImage}
                          alt="Dining Checked"
                          className="me-12"
                          height={15}
                        />
                        Dining
                      </button>
                    </div>
                  </div>
                  <div
                    className="locator-tool locator-tool__products ms-lg-1"
                    style={
                      {
                        /* zIndex: 1000 */
                      }
                    }
                  >
                    <p className="fs-5" style={{ marginBottom: '0.5rem' }}>
                      Products
                    </p>
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="dropdown-cuts" disabled={isLoading} className="px-0">
                        All Cuts
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="py-12 px-1" style={{ minWidth: 'max-content' }}>
                        <FormCheck
                          ref={allRef}
                          type="checkbox"
                          label="All"
                          defaultChecked
                          onClick={() => {
                            setAllCuts(defaultCuts)
                            checkAllCheckboxes()
                          }}
                        />
                        {optionsData !== null &&
                          optionsData !== undefined &&
                          optionsData.map((item, index) => {
                            if (!refs.current[item]) {
                              refs.current[item] = React.createRef()
                            }
                            return (
                              <FormCheck
                                key={item}
                                ref={refs.current[item]}
                                type="checkbox"
                                label={item}
                                value={item}
                                defaultChecked
                                onClick={addToCuts}
                              />
                            )
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <Row className="g-0 position-relative">
                {!loading && locationList.length <= 0 && <ErrorMapOverlay />}
                <Col sm={4} xl={3} className="overflow-scroll locations-list z-2">
                  <div className="d-flex" style={{ height: '100%', flexFlow: 'column' }}>
                    <div className="flex-grow-1 overflow-auto">
                      <div className="list-group list-group-flush border-top border-light">
                        {locationList !== null &&
                          locationList !== undefined &&
                          locationList.map((node, index) => (
                            <a
                              onClick={e => mapMenuEvent(e, node)}
                              href="#"
                              className="list-group-item list-group-item-action child-anchors"
                              key={index}
                            >
                              <h3 className="mb-14 mb-md-12">
                                {node.location_details.chain_name ? `${node.location_details.chain_name} - ` : ''}
                                {node.location_details.name}
                              </h3>
                              <p className="mb-14 mb-md-12">
                                {node.location_details.address_components.address}
                                <br />
                                {node.location_details.address_components.city},{' '}
                                {node.location_details.address_components.state}{' '}
                                {node.location_details.address_components.postal_code}
                              </p>

                              <Row>
                                {node.location_details.metadata?.urls?.reservations &&
                                  node.location_details.metadata?.urls?.reservations?.length >= 1 && <SectionDivider />}

                                {node.location_details.metadata?.urls?.reservations &&
                                  node.location_details.metadata?.urls?.reservations?.length >= 2 && (
                                    <SectionTitle title="Make Reservation" />
                                  )}
                                {node.location_details.metadata?.urls?.reservations && (
                                  <Col>
                                    {node.location_details.metadata?.urls?.reservations?.length &&
                                      node.location_details.metadata?.urls?.reservations.map((item, index) => {
                                        return (
                                          <>
                                            <a
                                              key={index}
                                              href="#"
                                              onClick={e =>
                                                openNewPage(
                                                  e,
                                                  item.url,
                                                  'reservation',
                                                  node.location_details,
                                                  node.id,
                                                  'reservation'
                                                )
                                              }
                                              className="ad-el-tracked"
                                              data-ad-element-type="locator - reservation"
                                              data-ad-location={item.url}
                                              data-ad-text="Make Reservation"
                                              data-ad-button-id="version 1"
                                              data-ad-button-location="map list"
                                            >
                                              {item.name}
                                            </a>
                                            <br />
                                          </>
                                        )
                                      })}
                                  </Col>
                                )}

                                {node.metadata?.takeout && (
                                  <Col>
                                    <a
                                      href="#"
                                      onClick={e =>
                                        openNewPage(
                                          e,
                                          node.metadata?.takeout,
                                          'takeout',
                                          node.location_details,
                                          node.id,
                                          'takeout'
                                        )
                                      }
                                      className="ad-el-tracked"
                                      data-ad-element-type="locator - order online"
                                      data-ad-location={node.location.name}
                                      data-ad-text="Order Online"
                                      data-ad-button-id="version 1"
                                      data-ad-button-location="map list"
                                    >
                                      Order Online
                                    </a>
                                  </Col>
                                )}
                                {node.metadata?.comingsoon && (
                                  <Col>
                                    <p className="mb-0 fst-italic">{node.metadata?.comingsoon}</p>
                                  </Col>
                                )}
                              </Row>
                            </a>
                          ))}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="align-self-end">
                        {paginationEnabled && <Pagination style={{ margin: 10 }}>{paginationItems}</Pagination>}
                      </div>
                    </div>
                  </div>
                </Col>
                <div className="scroll-overlay d-sm-none" />
                <Col sm={8} xl={9} className="position-relative">
                  {isLoading && (
                    <div
                      className="spinner-border position-absolute text-primary"
                      style={{ zIndex: '1000', left: 10, top: 15 }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={12}
                      options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        styles: [
                          {
                            featureType: 'administrative',
                            elementType: 'all',
                            stylers: [{ visibility: 'on' }, { saturation: -100 }, { lightness: 20 }],
                          },
                          {
                            featureType: 'road',
                            elementType: 'all',
                            stylers: [{ visibility: 'on' }, { saturation: -100 }, { lightness: 40 }],
                          },
                          {
                            featureType: 'water',
                            elementType: 'all',
                            stylers: [{ visibility: 'on' }, { saturation: -10 }, { lightness: 30 }],
                          },
                          {
                            featureType: 'landscape.man_made',
                            elementType: 'all',
                            stylers: [{ visibility: 'simplified' }, { saturation: -60 }, { lightness: 10 }],
                          },
                          {
                            featureType: 'landscape.natural',
                            elementType: 'all',
                            stylers: [{ visibility: 'simplified' }, { saturation: -60 }, { lightness: 60 }],
                          },
                          {
                            featureType: 'poi',
                            elementType: 'all',
                            stylers: [{ visibility: 'off' }, { saturation: -100 }, { lightness: 60 }],
                          },
                          {
                            featureType: 'transit',
                            elementType: 'all',
                            stylers: [{ visibility: 'off' }, { saturation: -100 }, { lightness: 60 }],
                          },
                        ],
                      }}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {locationList !== null &&
                        locationList !== undefined &&
                        locationList.map((node, index) => (
                          <Marker
                            icon={{
                              path:
                                'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z',
                              fillColor: 'red',
                              fillOpacity: 1,
                              scale: 0.05,
                            }}
                            key={index}
                            onClick={() => {
                              setInfoWindowData(node)
                              setInfoWindowIndex(index)
                              setShowInfoWindow(true)
                              GTMPush({
                                event: 'select_content',
                                postal_code: node.location_details.address_components.postal_code,
                                store_id: node.id,
                                content_type: 'store_selection',
                                store_name: node.location_details.name,
                                chain_name: node.location_details.chain_name,
                                search_type: 'store_locator',
                                order_type: 'delivery',
                              })
                            }}
                            position={{ lat: node.coordinates.latitude, lng: node.coordinates.longitude }}
                          />
                        ))}
                      {showInfoWindow && (
                        <InfoWindow
                          position={{
                            lat: infoWindowData.coordinates.latitude,
                            lng: infoWindowData.coordinates.longitude,
                          }}
                          options={{
                            pixelOffset: new window.google.maps.Size(10, -3),
                          }}
                          onCloseClick={() => {
                            setShowInfoWindow(false)
                          }}
                        >
                          <Container style={{ padding: 0, overflow: 'hidden' }}>
                            <Row>
                              <Col>
                                <h6>
                                  {infoWindowData.location_details.chain_name
                                    ? `${infoWindowData.location_details.chain_name} - `
                                    : ''}
                                  {infoWindowData.location_details.name}
                                </h6>
                              </Col>
                            </Row>
                            <Row className="g-0 justify-content-between">
                              <Col xs="auto">
                                {infoWindowData.location_details.phone && (
                                  <Link href={`tel:${infoWindowData.location_details.phone}`} className="mb-1">
                                    <FontAwesomeIcon icon={faMobile} /> {infoWindowData?.location_details.phone}
                                  </Link>
                                )}
                                <p className="mb-14 mb-md-12" style={{ marginTop: '0.5rem' }}>
                                  {infoWindowData.location_details.address_components.address}
                                  <br />
                                  {infoWindowData.location_details.address_components.city},{' '}
                                  {infoWindowData.location_details.address_components.state}{' '}
                                  {infoWindowData.location_details.address_components.postal_code}
                                </p>

                                {infoWindowData.location_details.metadata?.urls?.online_ordering?.length > 1 && (
                                  <p className="mb-0 ">
                                    <strong>Order Online</strong>
                                  </p>
                                )}
                                {infoWindowData.location_details.metadata?.urls?.online_ordering?.length &&
                                  infoWindowData.location_details.metadata?.urls?.online_ordering.map((item, index) => {
                                    return (
                                      <>
                                        <a
                                          key={index}
                                          href="#"
                                          onClick={e =>
                                            openNewPage(
                                              e,
                                              item.url,
                                              'orderonline',
                                              infoWindowData.location_details,
                                              infoWindowData.id,
                                              formatDisplay(item.name)
                                            )
                                          }
                                          className={`ad-el-tracked ${
                                            infoWindowData.location_details.metadata?.urls?.online_ordering?.length ===
                                            1
                                              ? 'fw-bold'
                                              : ''
                                          }`}
                                          data-ad-element-type="locator - orderonline"
                                          data-ad-location={item.url}
                                          data-ad-text="Order Online"
                                          data-ad-button-id="version 1"
                                          data-ad-button-location="map body"
                                        >
                                          {formatDisplay(item.name)}
                                        </a>
                                        <br />
                                      </>
                                    )
                                  })}

                                {infoWindowData.location_details.metadata?.urls?.reservations &&
                                  infoWindowData.location_details.metadata?.urls?.reservations?.length >= 1 && (
                                    <SectionDivider />
                                  )}

                                {infoWindowData.location_details.metadata?.urls?.reservations &&
                                  infoWindowData.location_details.metadata?.urls?.reservations?.length >= 2 && (
                                    <SectionTitle title="Make Reservation" />
                                  )}
                                {infoWindowData.location_details.metadata?.urls?.reservations && (
                                  <Col>
                                    {infoWindowData.location_details.metadata?.urls?.reservations?.length &&
                                      infoWindowData.location_details.metadata?.urls?.reservations.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <a
                                                key={index}
                                                href="#"
                                                onClick={e =>
                                                  openNewPage(
                                                    e,
                                                    item.url,
                                                    'reservation',
                                                    infoWindowData.location_details,
                                                    infoWindowData.id,
                                                    'reservation'
                                                  )
                                                }
                                                className="ad-el-tracked"
                                                data-ad-element-type="locator - reservation"
                                                data-ad-location={item.url}
                                                data-ad-text="Make Reservation"
                                                data-ad-button-id="version 1"
                                                data-ad-button-location="map body"
                                              >
                                                {item.name}
                                              </a>
                                              <br />
                                            </>
                                          )
                                        }
                                      )}
                                  </Col>
                                )}

                                <Row className="g-0 justify-content-between">
                                  {infoWindowData.reservation && (
                                    <Col>
                                      <a
                                        href="#"
                                        onClick={e =>
                                          openNewPage(
                                            e,
                                            infoWindowData.reservation,
                                            'reservation',
                                            infoWindowData,
                                            infoWindowData.id,
                                            'reservation'
                                          )
                                        }
                                        className="ad-el-tracked"
                                        data-ad-element-type="locator - reservation"
                                        data-ad-location={infoWindowData.name}
                                        data-ad-text="Make Reservation"
                                        data-ad-button-id="version 1"
                                        data-ad-button-location="map body"
                                      >
                                        Make Reservation
                                      </a>
                                    </Col>
                                  )}
                                  {infoWindowData.takeout && (
                                    <Col>
                                      <a
                                        href="#"
                                        onClick={e =>
                                          openNewPage(
                                            e,
                                            infoWindowData.takeout,
                                            'takeout',
                                            infoWindowData,
                                            infoWindowData.id,
                                            'takeout'
                                          )
                                        }
                                        className="ad-el-tracked"
                                        data-ad-element-type="locator - takeout"
                                        data-ad-location={infoWindowData.name}
                                        data-ad-text="Take out"
                                        data-ad-button-id="version 1"
                                        data-ad-button-location="map body"
                                      >
                                        Take out
                                      </a>
                                    </Col>
                                  )}
                                  {infoWindowData.comingsoon && (
                                    <Col>
                                      <p className="mb-0 fst-italic">{infoWindowData.comingsoon}</p>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                              <Col xs="auto" className="text-end justify-content-between ">
                                <Row>
                                  <p>{infoWindowData.coordinates.distance}</p>
                                </Row>
                                <Row>
                                  <p className="m-0">
                                    <strong>Products</strong>
                                  </p>
                                  <p className="text-nowrap m-0">
                                    {infoWindowData.products && cleanProductSet(infoWindowData.products)}
                                  </p>
                                </Row>
                              </Col>
                            </Row>
                          </Container>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  ) : (
                    <></>
                  )}
                  <div id="map" />
                </Col>
              </Row>
            </div>
          </section>
          <section id="WhereIsCTA" className="pt-3 pb-1 py-sm-3 py-lg-4 py-xl-5 text-center">
            <Container>
              <h2 className="mb-1 text-primary">Want more meati?</h2>
              <p className="lead mb-2 text-primary">We can't wait to be part of your world. Let's connect!</p>
              <Row className="g-1">
                <Col xs="6" md="3">
                  <Link className="rounded-4 bg-info p-1 d-block h-100 text-light" role="button" to="/sell">
                    <h3>Sell</h3>
                    <p className="mb-0 lh-1">Add Meati to your lineup</p>
                  </Link>
                </Col>
                <Col xs="6" md="3">
                  <a
                    className="rounded-4 bg-danger p-1 d-block h-100 text-warning"
                    role="button"
                    onClick={() => handleOpen()}
                  >
                    <h3>Request</h3>
                    <p className="mb-0 lh-1">Get Meati in your area</p>
                  </a>
                  <Modal
                    id="shareRecipeModal"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="Request Meati"
                    aria-describedby="Request Meati"
                    className="d-flex align-items-center"
                    disableRestoreFocus
                  >
                    <div className="bg-light modal-content mx-auto rounded-4">
                      <button className="btn btn-link close d-flex justify-content-end" onClick={handleClose}>
                        <FontAwesomeIcon className="fa-2x" icon={faTimes} />
                      </button>
                      <RequestForm
                        title="Request Meati"
                        subtitle="Let us know your zip code and we'll add your area to our list!"
                        buttonText="Request"
                      />
                    </div>
                  </Modal>
                </Col>
                <Col xs="6" md="3">
                  <a
                    className="rounded-4 bg-success p-1 d-block h-100 text-dark"
                    role="button"
                    href="https://www.instagram.com/meatifoods/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3>Follow Us</h3>
                    <p className="mb-0 lh-1">Get the scoop on Instagram</p>
                  </a>
                </Col>
                <Col xs="6" md="3">
                  <ModalContext.Consumer>
                    {context => (
                      <a
                        className="rounded-4 bg-warning p-1 d-block h-100 text-primary"
                        role="button"
                        onClick={() =>
                          context.openModal(
                            'Become a Meati insider and never miss the next big thing',
                            'Sign up to be the first to know when we launch in your area or drop a hot new product.'
                          )
                        }
                      >
                        <h3>Get Updates</h3>
                        <p className="mb-0 lh-1">Keep an eye on our location</p>
                      </a>
                    )}
                  </ModalContext.Consumer>
                </Col>
              </Row>
            </Container>
          </section>
        </Container>
      </div>
    </Layout>
  )
}

export default FindPage

import { useState, useEffect } from 'react'
import axios from 'axios'

const useOptions = url => {
  const [optionsData, setOptionsData] = useState(null)
  const [optionsLoading, setLoading] = useState(true)
  const [optionsError, setError] = useState(null)
  const muffinKey = '407bc7e2-a620-4205-bd71-77ae0d00d128'
  const queryURL = `https://api.muffindata.com/v1/locator/products`

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null)

      try {
        const postURL = queryURL
        const postHeaders = {
          headers: {
            'api-key': muffinKey,
          },
        }
        const response = await axios.get(postURL, postHeaders)

        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const uniqueArray = []
        response.data.results.forEach(value => {
          if (!uniqueArray.includes(value.product_name)) {
            uniqueArray.push(value.product_name)
          }
        })

        setOptionsData(uniqueArray)
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err.message)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return { optionsData, optionsLoading, optionsError }
}

export default useOptions

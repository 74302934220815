import React from 'react'

export const steakGrill = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Season</h3>
    </li>
    <li>
      <h3>Preheat &amp; oil</h3>
      <ul>
        <li>Preheat grill to medium heat</li>
        <li>Add a slight amount of oil to outside of steaks</li>
      </ul>
    </li>
    <li>
      <h3>Grill until browned</h3>
      <p>Flip every 2 minutes until outside is lightly browned and internal temp is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>12 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const steakSkillet = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Season</h3>
    </li>
    <li>
      <h3>Preheat &amp; oil</h3>
      <ul>
        <li>Preheat non-stick skillet to medium-low heat</li>
        <li>Add 1 tbsp oil to the skillet</li>
      </ul>
    </li>
    <li>
      <h3>Saut&eacute; until browned</h3>
      <p>Flip every 2 minutes until outside is lightly browned and internal temp is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>7 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const cutletGrill = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Season</h3>
    </li>
    <li>
      <h3>Preheat &amp; oil</h3>
      <ul>
        <li>Preheat grill to medium heat</li>
        <li>Add a slight amount of oil to outside of cutlets</li>
      </ul>
    </li>
    <li>
      <h3>Grill until browned</h3>
      <p>Flip every 2 minutes until outside is lightly browned and internal temp is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>6-8 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const cutletSkillet = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Season</h3>
    </li>
    <li>
      <h3>Preheat &amp; oil</h3>
      <ul>
        <li>Preheat non-stick skillet to medium-low heat</li>
        <li>Add 1 tbsp oil to the skillet</li>
      </ul>
    </li>
    <li>
      <h3>Saut&eacute; until browned</h3>
      <p>Flip every 2 minutes until outside is lightly browned and internal temp is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>7 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const crispyAirFryer = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Preheat</h3>
      <p className="mb-0">Preheat air fryer to 400&deg;F</p>
    </li>
    <li>
      <h3>Cook until crispy</h3>
      <p>Check that outside is crispy and center is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>6-8 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const crispyOven = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Preheat</h3>
      <p className="mb-0">Preheat oven to 375&deg;F</p>
    </li>
    <li>
      <h3>Cook until crispy</h3>
      <p>Check that outside is crispy and center is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>22 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const carneGrill = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Preheat &amp; oil</h3>
      <ul>
        <li>Preheat grill to medium heat</li>
        <li>Add a slight amount of oil to outside of steaks</li>
      </ul>
    </li>
    <li>
      <h3>Grill until browned</h3>
      <p>Flip every 2 minutes until outside is lightly browned and internal temp is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>12 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const carneSkillet = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Preheat &amp; oil</h3>
      <ul>
        <li>Preheat non-stick skillet to medium-low heat</li>
        <li>Add 1 tbsp oil to the skillet</li>
      </ul>
    </li>
    <li>
      <h3>Saut&eacute; until browned</h3>
      <p>Flip every 2 minutes until outside is browned and internal temp is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>8 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export const breakfastPatty = (
  <ol className="kitchen-instructions">
    <li>
      <h3>Preheat &amp; oil</h3>
      <ul>
        <li>Preheat nonstick skillet to medium heat</li>
        <li>Add 1 tbsp oil to the skillet</li>
      </ul>
    </li>
    <li>
      <h3>Saut&eacute; until browned</h3>
      <p>Flip every 2 minutes until outside is browned and internal temp is 165&deg;F.</p>
      <h4 className="h6">From thawed:</h4>
      Cook for <strong>7-9 minutes</strong>
    </li>
    <li>
      <h3>Remove and enjoy!</h3>
      <p>Remove, let rest for several minutes, and enjoy!</p>
    </li>
  </ol>
)

export default {
  steakGrill,
  steakSkillet,
  cutletGrill,
  cutletSkillet,
  crispyOven,
  crispyAirFryer,
  carneGrill,
  carneSkillet,
  breakfastPatty,
}
